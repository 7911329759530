<template>
  <div class="create-department">
    <form>
        <table>
            <tr height="50">
                <td ><span>Department Name:</span></td>
                <td><input type="text"></td>
            </tr>
            <tr height="50">
                <td ><span>Is Active</span></td>
                <td>
                    <span><input type="radio" name="p-type" value="1" >Yes</span>
                    <span><input type="radio" name="p-type" value="0" > No</span>
                </td>
            </tr>
            
             <tr height="50" >
                <td><span>Users:</span></td>
                <td><input type="text" ></td>
            </tr>
            <tr height="50">
                <td colspan="2" ><button @click.prevent="createDepartment">Create Department</button></td>
            </tr>
         
         
        </table>
    </form>
  </div>
</template>


<script>
export default {
    
}
</script>

<style scoped>
.create-department {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15%;
}
button{
    margin-left: 50%;
}
</style>